import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';

const CookieModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [acceptedCookiePolicy, setAcceptedCookiePolicy] = useState<any>(true);
  const now = Date.now();
  const cookiePolicyAccepted = {
    accepted: acceptedCookiePolicy,
    timestamp: now,
  };

  useEffect(() => {
    const cookiePolicyAcceptedInLocalSorage = localStorage.getItem('cookiePolicyAccepted');
    if (cookiePolicyAcceptedInLocalSorage) {
      setAcceptedCookiePolicy(true);
    } else {
      setAcceptedCookiePolicy(false);
    }
  }, []);

  useEffect(() => {
    if (!acceptedCookiePolicy) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [acceptedCookiePolicy]);

  const acceptCookiePolicy = () => {
    setAcceptedCookiePolicy(true);
    cookiePolicyAccepted.accepted = true;
    localStorage.setItem('cookiePolicyAccepted', JSON.stringify(cookiePolicyAccepted));
  };

  return (
    <section className={isOpen ? 'open cookie-modal' : 'closed'}>
      <div className="cookie-modal-content">
        <div>
          <p id="cookie-text">
            We use cookies to enhance your shopping experience. Visit our &nbsp;
            <a
              href={`${window.location.origin}/customer-care/cookie-policy/`}
              rel="noreferrer"
              target="_blank"
              id="cookie-policy-link"
            >
              Cookie Policy
            </a>
            &nbsp; page.
          </p>
        </div>
        <div>
          <Button
            variant="outlined"
            sx={{
              color: 'black',
              border: '1px solid black',
              '&:hover': {
                backgroundColor: 'inherit',
                boxShadow: 'none',
                border: '1px solid black',
              },
            }}
            onClick={acceptCookiePolicy}
          >
            Accept
          </Button>
        </div>
      </div>
    </section>
  );
};

export default CookieModal;
