import React, { useEffect, useRef, useState } from 'react';
import {
  applyDiscountCode,
  deleteDiscountCode,
  fetchCart,
  updateCart,
} from '../../redux/checkoutV2/utils';
import { fetchClient, moneyFormater, toastrError, toastrSuccess } from '../../redux/Helpers';
import Picture from '../Picture';
import './styles.scss';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import * as actionProduct from '../../redux/product/actions';
import {
  getCartTotal,
  getDiscountLineItem,
  getProductDiscounts,
  renderProductUrl,
} from '../../utils/helpers/functions';
import Contact from './Contact';
import { GET_ALL_FLAT_DISCOUNT, GET_PRODUCT_DETAIL } from '../../config';
import {
  CHECKOUT_ENDPOINT,
  GET_AVAILABLE_REWARDS,
  REFERRAL_PROGRAM,
  GET_GIFT_CARD,
  GET_USER_INFO,
} from '../../config';
import { setCheckoutV2StateAction } from '../../redux/checkoutV2/actions';
import store from '../../redux/store';
import { map } from 'lodash';
import SelectFreeProduct from './select_free_product';

export enum DiscountEnum {
  BXGYSameProduct = 'TEST BUY X GET Y  BUY 1 GET 1 SAME PRODUCT',
  BXGYDiffProduct = 'TEST BUY X GET Y BUY 1 GET 1 DIFFERENT PRODUCT',
}

const CheckoutV2Order = (props: any) => {
  const order = props?.order;
  const stateProduct = useSelector((state: RootStateOrAny) => state.productReducer);
  const [itemsQuantity, setItemsQuantity] = useState<any>([]);
  const [discountCode, setDiscountCode] = useState<any>('');
  const [referralCode, setReferralCode] = useState<any>('');
  const [removeReferral, setRemoveReferral] = useState<any>(false);
  const [giftCardPin, setGiftCardPin] = useState<string>('');
  const checkoutV2Reducer: any = useSelector((state: any) => state?.checkoutV2Reducer);
  const [discountsCode, setDiscountsCode] = useState<any>([]);
  const [discountsCodeUsed, setDiscountsCodeUsed] = useState<string>('');
  const dispatch = useDispatch();
  const [available_rewards, setAvailableRewards] = React.useState<any>();
  const [loading, setLoading] = useState(false);
  const [hasAddBox, sethasAddBox] = useState(false);
  const [dataGiftBox, setDataGiftBox] = useState<any>();
  const idCheckout =
    localStorage.getItem('id_checkout') && localStorage.getItem('id_checkout') != 'null'
      ? localStorage.getItem('id_checkout')
      : null;
  const ref: any = useRef();
  const [giftCards, setGiftCards] = useState<any>([]);
  const [userId, setUserId] = useState<any>(0);

  const fetchDataGiftCard = (user_id: any) => {
    const options = {
      url: GET_GIFT_CARD + '?user_id=' + user_id,
      method: 'GET',
      body: null,
    };
    fetchClient(options).then((res) => {
      setGiftCards(res);
    });
  };

  const fetchDataInfoUser = () => {
    if (userId) {
      fetchDataGiftCard(userId);
    } else {
      if (localStorage.getItem('sundoraToken')) {
        const options = {
          url: GET_USER_INFO,
          method: 'GET',
          body: null,
        };
        fetchClient(options).then((res) => {
          if (res?.success) {
            setUserId(res.data.id);
            fetchDataGiftCard(res.data.id);
          }
        });
      }
    }
  };

  // useEffect(() => {
  //   for (let i = 0; i < discountsCode.length; i++) {
  //     console.log(discountsCode[i]);
  //   }
  // }, [discountsCode]);

  const [referralProgram, setReferralProgram] = useState<any>(null);
  useEffect(() => {
    const url = REFERRAL_PROGRAM;
    const options = {
      method: 'GET',
      url: url,
      body: null,
    };
    fetchClient(options).then((res) => {
      setReferralProgram(res);
    });
  }, []);

  useEffect(() => {
    const lineItems: any = [];
    order?.line_items?.forEach((i: any) => {
      lineItems.push({
        variant_id: i?.variant_id,
        quantity: i?.quantity,
        applied_discount: i?.applied_discount,
      });
    });
    setItemsQuantity(lineItems);
  }, [order]);

  // useEffect(() => {
  //   console.log(itemsQuantity);
  // }, [itemsQuantity]);

  useEffect(() => {
    const AUTH_TOKEN = `Token ${localStorage.getItem('sundoraToken')}`;
    let defaultHeaders = {
      'Content-Type': 'application/json',
    };
    if (localStorage.getItem('sundoraToken')) {
      defaultHeaders = {
        ...defaultHeaders,
        ...{ authorization: AUTH_TOKEN },
      };
    }
    const options = {
      url: GET_ALL_FLAT_DISCOUNT,
      method: 'GET',
      headers: defaultHeaders,
      body: null,
    };
    fetchClient(options).then((res: any) => {
      if (res?.statusCode == 200) {
        setDiscountsCode(res?.data);
      }
    });
  }, [discountCode]);

  const handleChangeQuantity = (item: any, action: string) => {
    item.quantity =
      item?.applied_discount?.title === DiscountEnum.BXGYSameProduct
        ? action == 'decrement'
          ? item.quantity - 1
          : item.quantity + 1
        : item.quantity;
    setItemsQuantity((prev: any) => {
      const line_item = {
        variant_id: item?.variant_id ?? '',
        quantity: item?.applied_discount?.title === DiscountEnum.BXGYSameProduct ? 2 : 1,
        action:
          item.quantity >= 1 && item?.applied_discount?.title !== DiscountEnum.BXGYSameProduct
            ? action
            : item.quantity > 2 &&
              item?.applied_discount?.title === DiscountEnum.BXGYSameProduct
            ? action
            : 'remove',
      };
      let newState: any;
      if (parseInt(item?.quantity) < 1) {
        newState = [...prev?.filter((x: any) => x?.variant_id != item?.variant_id)];
      } else {
        newState = [...prev?.filter((x: any) => x?.variant_id != item?.variant_id), item];
      }
      clearTimeout(ref.current);
      ref.current = setTimeout(() => {
        updateCart(newState, null, null, line_item);
      }, 500);
      return newState;
    });
  };

  const totalPriceItem = (item: any, discount: any = false) => {
    if (item?.price && item?.price > 0) {
      if (discount && parseFloat(item?.applied_discount?.amount) > 0) {
        return moneyFormater(
          parseFloat(item?.price) * parseFloat(item?.quantity) -
            parseFloat(item?.applied_discount?.amount),
        );
      }
      return moneyFormater(parseFloat(item?.price) * parseFloat(item?.quantity));
    } else {
      return 'GIFT';
    }
  };

  const handleApplyDiscount = () => {
    applyDiscountCode(discountCode);
  };

  const handleDeleteDiscount = async () => {
    if (discountCode?.length < 1) {
      return;
    }
    const success: any = await deleteDiscountCode();
    if (success) {
      setDiscountCode('');
    }
  };
  const handle_click_discount_code = (discountCode: any) => {
    applyDiscountCode(discountCode);
    setDiscountsCodeUsed(discountCode);
  };
  const render_discount_code = (item: any) => {
    return (
      <div className="component-CheckoutV2Order-list-code">
        <div className="CheckoutV2Order-promo-list-code">
          <div className="CheckoutV2Order-title-list-code">Use Discount Voucher</div>
          <hr />
          <div className="CheckoutV2Order-space-code">
            {item?.map((i: any, index: number) => (
              <div className="CheckoutV2Order-item-list-code" key={index}>
                <button
                  className="CheckoutV2Order-btn-apply-discount"
                  onClick={() => handle_click_discount_code(i?.discount_code)}
                >
                  <div className="discount_title">
                    {i?.description
                      ? i?.discount_code + ' ' + i?.description
                      : i?.discount_code}
                  </div>
                  <div className="discount_expiry">
                    <div className="discount_expiry-inner">
                      <>Expires on</>
                      <br></br>
                      {i?.valid_until
                        ? i?.valid_until?.substring(0, 10).split('-').reverse().join('-')
                        : ''}
                    </div>
                  </div>
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const handleReferralDiscount = async (
    newCheckedValue: boolean,
    removeDiscountReferral?: boolean,
  ) => {
    setLoading(true);
    if (typeof newCheckedValue !== 'boolean' && newCheckedValue) {
      store.dispatch(setCheckoutV2StateAction({ referralCode: newCheckedValue }));
      store.dispatch(
        setCheckoutV2StateAction({ removeDiscountReferral: removeDiscountReferral }),
      );
      setRemoveReferral(removeDiscountReferral);
    }
    if (idCheckout) {
      let aff = '';
      // let id_user = '';
      // let id_token = '';
      // let aff_uid = '';
      // get affiliate code in cookie
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf('aff=') == 0) {
          aff = c.substring(4, c.length);
        }
      }

      type BodyObjectType = {
        draft_order: {
          line_items: any;
        };
        flag: boolean;
        referral_code: any;
        available_rewards?: number;
        aff: string;
      };
      const url_update_cart = `${CHECKOUT_ENDPOINT}${idCheckout}/`;
      const bodyObject: BodyObjectType = {
        draft_order: {
          line_items: stateProduct?.product?.line_items,
        },
        flag:
          removeDiscountReferral == false
            ? removeDiscountReferral
            : typeof newCheckedValue !== 'boolean'
            ? true
            : newCheckedValue,
        referral_code: referralCode ? referralCode : null,
        aff: aff,
      };

      if (available_rewards > 0) {
        bodyObject.available_rewards = available_rewards;
      }

      const options_update_cart = {
        method: 'PUT',
        url: url_update_cart,
        body: JSON.stringify(bodyObject),
      };
      try {
        const res = await fetchClient(options_update_cart);
        if (res?.success) {
          dispatch(
            actionProduct.setCheckoutProductAndLineItems({
              product: res?.data,
              lineItems: res?.data?.draft_order?.line_items,
            }),
          );
          dispatch(setCheckoutV2StateAction(res?.data));
          if (res?.data?.message) {
            toastrError(res?.data?.message);
            fetchCart();
          }
        } else if (res.isError) {
          console.log('res.isError: ', res.isError);
        }
      } catch (error) {
        console.error(error);
      }
    }
    setLoading(false);
  };

  const handleGiftCard = async (removeGiftCard = false, pin = '') => {
    setLoading(true);
    setGiftCardPin(pin);

    store.dispatch(setCheckoutV2StateAction({ giftCardPin: pin, loading: true }));
    // applyGiftCard(giftCardPin, stateProduct?.product?.line_items);
    if (idCheckout) {
      type BodyObjectType = {
        draft_order: {
          line_items: any;
        };
        pin_gift_card: any;
        discount_code: any;
        flag: boolean;
        referral_code: any;
        available_rewards: number;
      };
      const url_update_cart = `${CHECKOUT_ENDPOINT}${idCheckout}/`;
      const bodyObject: BodyObjectType = {
        draft_order: {
          line_items: stateProduct?.product?.line_items,
        },
        pin_gift_card: pin && !removeGiftCard ? pin : null,
        discount_code: discountCode ? discountCode : null,
        flag: removeReferral ? removeReferral : false,
        referral_code: referralCode ? referralCode : null,
        available_rewards: available_rewards,
      };

      const options_update_cart = {
        method: 'PUT',
        url: url_update_cart,
        body: JSON.stringify(bodyObject),
      };
      try {
        const res = await fetchClient(options_update_cart);
        if (res?.success) {
          store.dispatch(setCheckoutV2StateAction({ loading: false }));
          dispatch(
            actionProduct.setCheckoutProductAndLineItems({
              product: res?.data,
              lineItems: res?.data?.draft_order?.line_items,
            }),
          );
          dispatch(setCheckoutV2StateAction(res?.data));
          if (res?.data?.message) {
            toastrError(res?.data?.message);
            fetchCart();
          } else {
            if (removeGiftCard) {
              store.dispatch(setCheckoutV2StateAction({ giftCardPin: null }));
              toastrSuccess(`Gift Card ${pin} was successfully removed from cart`);
            } else {
              fetchDataInfoUser();
              toastrSuccess(`Gift Card ${pin} was successfully applied to cart`);
            }
          }
        } else if (res.isError) {
          store.dispatch(setCheckoutV2StateAction({ loading: false }));
          console.log('res.isError: ', res.isError);
        }
      } catch (error) {
        store.dispatch(setCheckoutV2StateAction({ loading: false }));
        console.error(error);
      }
    }
    setLoading(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = event.target.checked;
    props.receiveFlagCallBack(newCheckedValue);
    if (newCheckedValue == true) {
      handleReferralDiscount(newCheckedValue);
    } else {
      handleReferralDiscount(newCheckedValue);
    }
  };
  useEffect(() => {
    const url = GET_AVAILABLE_REWARDS; // replace with your API endpoint
    const options = {
      method: 'GET',
      url: url,
      body: null,
    };

    fetchClient(options).then((res) => {
      if (res?.available_rewards) {
        store.dispatch(setCheckoutV2StateAction({ available_rewards: res.available_rewards }));
        setAvailableRewards(res.available_rewards);
      } else if (res?.isError) {
        console.log('res.isError: ', res?.isError);
      }
    });
    fetchDataInfoUser();
  }, []);

  useEffect(() => {
    const url = GET_PRODUCT_DETAIL + 'sundora-beauty-gift-box/';
    const options = {
      method: 'GET',
      url: url,
      body: null,
    };
    fetchClient(options).then((res) => {
      const box_green = res?.product_variants?.find(
        (x: any) => x?.variant_id === '44545187053732',
      );
      if (box_green?.inventory_quantity > 0) {
        setDataGiftBox(box_green);
      } else {
        const box_beige = res?.product_variants?.find(
          (x: any) => x?.variant_id != '44545187053732',
        );
        if (box_beige?.inventory_quantity > 0) {
          setDataGiftBox(box_beige);
        } else {
          setDataGiftBox(null);
        }
      }
    });
  }, []);

  const handleCheckboxAddBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setCheckoutV2StateAction({ loading: true }));
    const newCheckedValue = event.target.checked;
    sethasAddBox(newCheckedValue);
    const variant = dataGiftBox;

    const line_item = {
      variant_id: variant?.variant_id ?? '',
      quantity: 1,
      action: newCheckedValue ? 'increment' : 'decrement',
    };

    if (newCheckedValue) {
      const data = {
        variant_id: variant?.variant_id ?? '',
        quantity: 1,
      };

      setItemsQuantity((prev: any) => {
        const newState = [...prev?.filter((x: any) => x?.variant_id != data?.variant_id)];
        clearTimeout(ref.current);
        ref.current = setTimeout(() => {
          updateCart(newState, null, null, line_item);
        }, 500);
        return newState;
      });

      toastrSuccess(`Sundora Beauty Gift Box has been added to your cart`);
    } else {
      setItemsQuantity((prev: any) => {
        const newState = [...prev?.filter((x: any) => x?.variant_id != variant?.variant_id)];

        clearTimeout(ref.current);
        ref.current = setTimeout(() => {
          updateCart(newState, null, null, line_item);
        }, 500);
        return newState;
      });
      toastrSuccess(`Sundora Beauty Gift Box has been removed from your cart`);
    }
    dispatch(setCheckoutV2StateAction({ loading: false }));
  };

  const handleDataFromChild = (DiscountedProducts: any) => {
    if (order.line_items && order.line_items.length > 0) {
      for (const item of order.line_items) {
        if (
          item?.applied_discount?.description === 'DiscountAutomaticBxgy' &&
          item?.applied_discount?.title !== DiscountedProducts?.discountTitle &&
          item?.applied_discount?.title.includes('Buy Over')
        ) {
          setItemsQuantity((prev: any) => {
            const line_item = {
              variant_id: item?.variant_id ?? '',
              quantity: 1,
              action: 'remove',
            };
            let newState: any;
            if (parseInt(item?.quantity) < 1) {
              newState = [...prev?.filter((x: any) => x?.variant_id != item?.variant_id)];
            } else {
              newState = [
                ...prev?.filter((x: any) => x?.variant_id != item?.variant_id),
                item,
              ];
            }
            clearTimeout(ref.current);
            ref.current = setTimeout(() => {
              updateCart(newState, null, null, line_item);
            }, 500);
            return newState;
          });
        }
      }
    }
  };

  const handleBxGyFromChild = (checkBxGy: any) => {
    if (checkBxGy == true) {
      const items = order?.line_items.filter(
        (e: any) => e.applied_discount.title === DiscountEnum.BXGYDiffProduct,
      );
      if (items.length > 0) {
        for (const item of items) {
          handleChangeQuantity(
            {
              variant_id: item?.variant_id,
              applied_discount: item?.applied_discount,
              price: item?.price,
              quantity: 0,
            },
            'remove',
          );
        }
      }
    } else {
      const items = order?.line_items.filter(
        (e: any) => e.applied_discount.title === DiscountEnum.BXGYDiffProduct,
      );
      const arrBxGyId = [];
      for (const dc of checkBxGy) {
        const id =
          (dc.raw_data?.customerGets?.items?.productVariants?.edges[0]?.node?.id).split('/');
        arrBxGyId.push(String(id[id.length - 1]));
      }
      if (items.length > 0) {
        for (const item of items) {
          if (arrBxGyId.includes(String(item.variant_id))) continue;
          else {
            handleChangeQuantity(
              {
                variant_id: item?.variant_id,
                applied_discount: item?.applied_discount,
                price: item?.price,
                quantity: 0,
              },
              'remove',
            );
          }
        }
      }
    }
  };

  const handleFreeProductFromChild = (checkFreeProduct: any) => {
    if (checkFreeProduct == true) {
      const items = order?.line_items.filter(
        (e: any) =>
          e.applied_discount.description === 'DiscountAutomaticBxgy' &&
          e.applied_discount.title.includes('Buy Over'),
      );
      if (items.length > 0) {
        for (const item of items) {
          handleChangeQuantity(
            {
              variant_id: item?.variant_id,
              applied_discount: item?.applied_discount,
              price: item?.price,
              quantity: 0,
            },
            'remove',
          );
        }
      }
    }
  };

  return (
    <div className={`component-CheckoutV2Order`}>
      <Contact />
      {order && order.line_items?.length > 0 ? (
        <>
          <SelectFreeProduct
            order={order}
            sentDiscountedProductToCart={handleDataFromChild}
            sentBxGyDiffProductToCart={handleBxGyFromChild}
            sentFreeProductToCart={handleFreeProductFromChild}
          />
        </>
      ) : (
        <></>
      )}
      <div className="CheckoutV2Order-order">
        <div className="CheckoutV2Order-title">Shopping Bag</div>

        {available_rewards && available_rewards > 0 ? (
          <div className="wrapper-referral">
            <div className="CheckoutV2Order-toggle-referral-discount">
              Apply Referral Discount
            </div>
            <div className="checkbox-wrapper-6">
              <input
                className="tgl tgl-light"
                id="cb1-6"
                type="checkbox"
                onChange={handleCheckboxChange}
              />
              <label className="tgl-btn" htmlFor="cb1-6"></label>
            </div>
          </div>
        ) : null}
        {loading ? (
          <div className="wrapper-loading">
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className="CheckoutV2Order-products">
            {order?.line_items
              ?.sort((a: any, b: any) => {
                if (parseInt(a?.product_id) > parseInt(b?.product_id)) {
                  return 1;
                }
                return -1;
              })
              ?.map((item: any, i: any) => {
                const itemQuantity: any = itemsQuantity?.find(
                  (x: any) => x?.variant_id == item?.variant_id,
                );
                return (
                  <div className="CheckoutV2Order-product" key={i}>
                    <a
                      href={renderProductUrl(item?.product_url)}
                      className="CheckoutV2Order-image"
                    >
                      {item?.applied_discount && (
                        <div className="discount">{getDiscountLineItem(item)}</div>
                      )}
                      <Picture data={item?.image?.url} />
                    </a>
                    <a
                      href={renderProductUrl(item?.product_url)}
                      className="CheckoutV2Order-info"
                    >
                      <div className="CheckoutV2Order-name">{item?.title}</div>
                      <div className="CheckoutV2Order-brand">{item?.vendor}</div>
                      <div className="CheckoutV2Order-variant">{item?.variant_title}</div>
                    </a>
                    <div className="CheckoutV2Order-pq">
                      <div className="CheckoutV2Order-price">
                        {parseFloat(item?.applied_discount?.amount) > 0 && (
                          <div className="linethrough">{totalPriceItem(item)}</div>
                        )}
                        <div>{totalPriceItem(item, true)}</div>
                      </div>
                      <div
                        className={
                          item?.price > 0
                            ? 'CheckoutV2Order-quantity'
                            : 'CheckoutV2Order-quantity-disabled'
                        }
                      >
                        <button
                          disabled={item?.price <= 0}
                          onClick={() =>
                            handleChangeQuantity(
                              {
                                variant_id: item?.variant_id,
                                applied_discount: item?.applied_discount,
                                price: item?.price,
                                quantity:
                                  parseInt(itemQuantity?.quantity) > 1
                                    ? parseInt(itemQuantity?.quantity) - 1
                                    : 0,
                              },
                              'decrement',
                            )
                          }
                        >
                          -
                        </button>
                        <div>{itemQuantity?.quantity}</div>
                        <button
                          disabled={item?.price <= 0}
                          onClick={() =>
                            handleChangeQuantity(
                              {
                                variant_id: item?.variant_id,
                                applied_discount: item?.applied_discount,
                                price: item?.price,
                                quantity: parseInt(itemQuantity?.quantity) + 1,
                              },
                              'increment',
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div className="out-stock">
                      {item?.inventory_quantity < 1 &&
                        'This product is out of stock, please remove it from your cart'}
                    </div>
                  </div>
                );
              })}
          </div> // This will be displayed if loading is false
        )}

        {order?.shopify_discount_info?.title && (
          <div className="CheckoutV2Order-calculate">
            <div className="CheckoutV2Order-row">
              <div className="CheckoutV2Order-col1">Applied discount</div>
              <div className="CheckoutV2Order-col2" style={{ width: '60%' }}>
                {order?.shopify_discount_info?.summary
                  .replace('à§³', '\u09F3')
                  .replace('â\u0080¢', ' at ')}
              </div>
            </div>
          </div>
        )}

        <div className="CheckoutV2Order-calculate">
          <div className="CheckoutV2Order-row">
            <div className="CheckoutV2Order-col1">Cart total</div>
            <div className="CheckoutV2Order-col2">
              {moneyFormater(getCartTotal(order?.line_items))}
            </div>
          </div>

          {(() => {
            const productDiscounts: any = getProductDiscounts(order?.line_items);
            if (productDiscounts > 0) {
              return (
                <div className="CheckoutV2Order-row">
                  <div className="CheckoutV2Order-col1">Product discounts</div>
                  <div className="CheckoutV2Order-col2">
                    -({moneyFormater(getProductDiscounts(order?.line_items))})
                  </div>
                </div>
              );
            }
          })()}

          <div className="divide"></div>

          <div className="CheckoutV2Order-row">
            <div className="CheckoutV2Order-col1">sub total</div>
            <div className="CheckoutV2Order-col2">
              {moneyFormater(order?.subtotal_price_with_rebate || 0)}
            </div>
          </div>

          {parseFloat(order?.applied_discount?.amount) > 0 &&
            order?.applied_discount?.description != 'gift_cart' && (
              <div className="CheckoutV2Order-row">
                <div className="CheckoutV2Order-col1">Discount</div>
                <div className="CheckoutV2Order-col2">
                  {order?.applied_discount?.amount
                    ? '- (' + moneyFormater(order?.applied_discount?.amount) + ')'
                    : moneyFormater(0)}
                </div>
              </div>
            )}

          <div className="CheckoutV2Order-row">
            <div className="CheckoutV2Order-col1">vat(+7.5%)</div>
            <div className="CheckoutV2Order-col2">{moneyFormater(order?.total_tax || 0)}</div>
          </div>

          <div className="CheckoutV2Order-row">
            <div className="CheckoutV2Order-col1">Shipping</div>
            <div className="CheckoutV2Order-col2">
              {checkoutV2Reducer?.displayShippingPrice
                ? parseFloat(order?.shipping_line?.price) > 0
                  ? moneyFormater(order?.shipping_line?.price)
                  : 'FREE'
                : 'Select location for shipping price'}
            </div>
          </div>

          {parseFloat(order?.gift_card?.amount_discount) > 0 && (
            <div className="CheckoutV2Order-row">
              <div className="CheckoutV2Order-col1">
                Gift Card {order?.gift_card?.code ? order?.gift_card?.code : ''}
              </div>
              <div className="CheckoutV2Order-col2">
                {order?.gift_card?.amount_discount
                  ? '- (' + moneyFormater(order?.gift_card?.amount_discount) + ')'
                  : moneyFormater(0)}
              </div>
            </div>
          )}

          <div className="CheckoutV2Order-row CheckoutV2Order-total">
            <div className="CheckoutV2Order-col1">Total</div>
            <div className="CheckoutV2Order-col2">
              {moneyFormater(order?.total_price || 0)}
            </div>
          </div>
          <div className="CheckoutV2Order-row">
            <div className="CheckoutV2Order-col1">Applied Voucher</div>
            <div className="CheckoutV2Order-col2">{discountsCodeUsed}</div>
          </div>
        </div>
      </div>
      {/* add checkbox "Add Sundora Beauty Gift Box" */}
      {dataGiftBox ? (
        <div className="CheckoutV2Order-promo">
          <div className="CheckoutV2Order-title input-group checkbox-wrapper-6 mx-0 add-gift-box">
            <input
              //className="tgl tgl-light"
              style={{ marginRight: '10px' }}
              id="cb2-6"
              type="checkbox"
              checked={hasAddBox}
              onChange={handleCheckboxAddBox}
            />
            {`Add a Sundora Beauty Gift Box - ${dataGiftBox?.title} for ৳${dataGiftBox?.price}`}

            <img
              style={{ maxWidth: '100px' }}
              alt="Sundora Beauty Gift Box"
              src={
                dataGiftBox?.image?.url?.webp
                  ? dataGiftBox?.image?.url?.webp
                  : dataGiftBox?.image?.url?.original
              }
            />
            <label className="tgl-btn" htmlFor="cb2-6"></label>
          </div>
        </div>
      ) : null}

      <div className="CheckoutV2Order-promo">
        <div className="CheckoutV2Order-title">COUPON CODE:</div>
        <div className="utilities-form-control">
          <div className="input-group">
            <input
              type="text"
              placeholder="Got a coupon code?"
              value={discountCode}
              onChange={(e: any) => setDiscountCode(e?.target?.value)}
            />
            {discountCode && (
              <button className="remove-coupon" onClick={handleDeleteDiscount}>
                Remove coupon code
              </button>
            )}
          </div>
          <button className="button" onClick={handleApplyDiscount}>
            Submit
          </button>
        </div>
      </div>
      {discountsCode.length > 0 ? render_discount_code(discountsCode) : null}
      {(available_rewards < 1 || referralProgram?.data.length <= 0) && (
        <div className="CheckoutV2Order-promo">
          <div className="CheckoutV2Order-title">REFERRAL CODE:</div>
          <div className="utilities-form-control">
            <div className="input-group">
              <input
                type="text"
                placeholder="Got a referral code?"
                value={referralCode}
                onChange={(e: any) => setReferralCode(e?.target?.value)}
              />
              {referralCode && (
                <button
                  className="remove-coupon"
                  onClick={() => {
                    handleReferralDiscount(referralCode, false);
                    setReferralCode('');
                  }}
                >
                  Remove referral code
                </button>
              )}
            </div>
            <button
              className="button"
              onClick={() => handleReferralDiscount(referralCode, true)}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <div className="CheckoutV2Order-promo">
        <div className="CheckoutV2Order-title">USE GIFT CARD:</div>
        <div className="utilities-form-control">
          <div className="input-group">
            <input
              type="text"
              placeholder="Input Gift Card Pin"
              value={giftCardPin}
              onChange={(e: any) => setGiftCardPin(e?.target?.value)}
            />
            {giftCardPin && (
              <button
                className="remove-coupon"
                onClick={() => {
                  handleGiftCard(true, '');
                  setGiftCardPin('');
                }}
              >
                Remove gift card
              </button>
            )}
          </div>
          <button className="button" onClick={() => handleGiftCard(false, giftCardPin)}>
            Submit
          </button>
        </div>
        {giftCards.length > 0 ? (
          <div className="wrapper-month-list my-2">
            <div className="table-custom-order-history">
              <b>Redeemed Giftcards:</b>
            </div>
            <div className="main">
              {Object.keys(giftCards).length
                ? map(Object.keys(giftCards), (key: any, index: number) => {
                    return (
                      <div className="row my-1" key={index}>
                        <a
                          style={{ cursor: 'pointer', color: '#2a676b' }}
                          onClick={() => {
                            handleGiftCard(false, giftCards[key].pin);
                          }}
                        >
                          <div className="col-12">
                            {giftCards[key].code} (৳{giftCards[key].balance})
                          </div>
                        </a>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default CheckoutV2Order;
