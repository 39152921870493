import axios from 'axios';
import React, { useEffect, useState } from 'react';
import AdaptiveInput from '../CheckoutForm/AdaptiveInput';
import { VERIFY_SMS, VERIFY_SMS_GUEST } from '../../config';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { toastrError, toastrSuccess } from '../../redux/Helpers';
import { setCheckoutV2StateAction } from '../../redux/checkoutV2/actions';
import { sendEmailVerifyOTP, sendSmsVerifyCode } from '../../redux/checkoutV2/utils';
import { isNumeric } from '../../utils/helpers/functions';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { PHONE_CONTACT } from '../../config';
import { Button } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import DotLoader from '../DotLoader';

let SMSTimerInterval: any;
let OTPInterval: any;
export enum EOTPType {
  SMS = 'sms',
  EMAIL = 'email',
}

const SMSVerify = ({
  dataForm,
  setOpenSMSVerify,
  handleVerify: handleVerifyInProps,
  OTPType,
  trigger,
}: {
  dataForm?: any;
  setOpenSMSVerify?: any;
  handleVerify?: any;
  OTPType?: EOTPType;
  trigger?: any;
}) => {
  const dispatch = useDispatch();
  const settingsReducer = useSelector((state: any) => state?.siteSettingsReducer);

  const [code, setCode] = useState<any>('');
  const [errorMessage, setErrorMessage] = useState<any>('');
  const [loadingResend, setLoadingResend] = useState<any>(false);
  const [resendDisabled, setResendDisabled] = useState<boolean>(false);
  const [remainingDisabledTime, setRemainingDisabledTime] = useState<number>(0);
  const [remainingOTPTime, setRemainingOTPTime] = useState<number>(0);
  const [OTPisValid, setOTPisValid] = useState<boolean>(true);
  const [guestNumber, setGuestNumber] = useState<string>('');
  const [countOTPResends, setCountOTPResends] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const OTP_VALID_TIME = settingsReducer.OTP_VALIDITY * 60;
  const DISABLE_SMS_RESEND_TIME = settingsReducer?.SMS_TIMER * 60;

  const guestPhone = useSelector((state: any) => state?.checkoutV2Reducer).guestNumber;
  const stateUser = useSelector((state: RootStateOrAny) => state.userReducer);
  useEffect(() => {
    setGuestNumber(guestPhone);
  }, [guestPhone]);

  const handleChange = (e: any) => {
    const value: any = e.target.value;
    if (value?.length > 0 && (value?.length > 6 || !isNumeric(value))) {
      return;
    }
    setCode(e.target.value);
  };

  const SMSdisabledInStorage = () => {
    const now = Math.floor(Date.now() / 1000);
    let timeLimit = 0;
    const disabledResendSMSTimeInStorage = localStorage.getItem('VSMSBlockTime');
    if (disabledResendSMSTimeInStorage) {
      timeLimit = parseInt(JSON.parse(disabledResendSMSTimeInStorage));
    }
    if (timeLimit > now) {
      setRemainingDisabledTime(timeLimit - now);
      return true;
    } else {
      localStorage.removeItem('VMSBlockTime');
    }
    return false;
  };

  const OTPexpiryInStorage = () => {
    const now = Math.floor(Date.now() / 1000);
    let timeLimit = 0;
    const OTPExpInStorage = localStorage.getItem('OTPexpiry');
    if (OTPExpInStorage) {
      timeLimit = parseInt(JSON.parse(OTPExpInStorage));
    }
    if (timeLimit > now) {
      setRemainingOTPTime(timeLimit - now);
      return true;
    } else {
      localStorage.removeItem('OPTexpiry');
    }
    return false;
  };

  const disableResendSMS = () => {
    const isDisabled = SMSdisabledInStorage();
    if (!isDisabled) {
      const disableSMSResendUntil = Math.floor(Date.now() / 1000) + DISABLE_SMS_RESEND_TIME;
      setRemainingDisabledTime(DISABLE_SMS_RESEND_TIME);
      localStorage.setItem('VSMSBlockTime', JSON.stringify(disableSMSResendUntil));
    }
    setResendDisabled(true);
  };

  const setOTPValidity = () => {
    const OTPinStorage = OTPexpiryInStorage();
    if (!OTPinStorage) {
      const OTPValidUntil = Math.floor(Date.now() / 1000) + OTP_VALID_TIME;
      setRemainingOTPTime(OTPValidUntil);
      localStorage.setItem('OTPexpiry', JSON.stringify(OTPValidUntil));
    }
    setOTPisValid(true);
  };

  useEffect(() => {
    const isDisabled = SMSdisabledInStorage();
    if (!isDisabled) {
      setResendDisabled(false);
    } else {
      setResendDisabled(true);
    }
  }, [remainingDisabledTime, setOpenSMSVerify]);

  useEffect(() => {
    const isValid = OTPexpiryInStorage();
    if (!isValid) {
      setOTPisValid(false);
    } else {
      setOTPisValid(true);
    }
  }, [remainingOTPTime, trigger]);

  useEffect(() => {
    if (resendDisabled) {
      SMSTimerInterval = setInterval(() => {
        setRemainingDisabledTime((prevRemainingDisabledTime) => prevRemainingDisabledTime - 1);
      }, 1000);
    }
    return () => clearInterval(SMSTimerInterval);
  }, [resendDisabled]);

  useEffect(() => {
    if (remainingDisabledTime <= 0) {
      clearInterval(SMSTimerInterval);
      setResendDisabled(false);
    }
  }, [remainingDisabledTime]);

  useEffect(() => {
    if (OTPisValid) {
      OTPInterval = setInterval(() => {
        setRemainingOTPTime((prevRemainingOTPTime) => prevRemainingOTPTime - 1);
      }, 1000);
    }
    return () => clearInterval(OTPInterval);
  }, [OTPisValid]);

  useEffect(() => {
    if (remainingOTPTime <= 0) {
      clearInterval(OTPInterval);
      setOTPisValid(false);
    }
  }, [remainingOTPTime]);

  const handleVerify = () => {
    setLoading(true);
    if (handleVerifyInProps) {
      handleVerifyInProps({
        email:
          dataForm?.email && dataForm.email.length > 0
            ? dataForm.email
            : stateUser.hashedUser.email,
        phone: dataForm?.phone,
        verify_sms_code: code,
        code: code,
        draft_order_id: localStorage.getItem('id_checkout'),
      });
    } else {
      if (OTPType == EOTPType.EMAIL) {
        //
      } else {
        axios
          .post(dataForm?.guest ? VERIFY_SMS_GUEST : VERIFY_SMS, {
            email: dataForm?.email,
            phone: dataForm?.phone,
            verify_sms_code: code,
            code: code,
            draft_order_id: localStorage.getItem('id_checkout'),
          })
          .then((res: any) => {
            toastrSuccess('Verified successfully');
            dispatch(
              setCheckoutV2StateAction({
                smsVerifyAsGuest: true,
                guestPhone: dataForm?.phone,
              }),
            );
            if (res.data?.data) {
              // dispatch(action.verificationSuccessActions(res.data?.data));
              setLoading(false);
              setOpenSMSVerify && setOpenSMSVerify(false);
            } else {
              setLoading(false);
              setOpenSMSVerify && setOpenSMSVerify(false);
            }
          })
          .catch(() => {
            setLoading(false);
            toastrError('Code incorrect or expired');
            setErrorMessage('Code incorrect or expired');
          });
      }
    }
  };

  const handleResend = async () => {
    if (OTPType == EOTPType.EMAIL) {
      setLoadingResend(true);
      await sendEmailVerifyOTP({
        email: dataForm?.email || dataForm?.phone,
      })
        .then(() => {
          setLoadingResend(false);
          disableResendSMS();
          setOTPValidity();
          toastrSuccess('OTP Resend successfull');
        })
        .catch((error: any) => {
          setLoadingResend(false);
          toastrError(error?.response?.data?.message);
        });
    } else {
      setLoadingResend(true);
      await sendSmsVerifyCode(
        {
          email:
            stateUser?.hashedUser?.email && stateUser.hashedUser.email.length > 0
              ? stateUser.hashedUser.email
              : dataForm?.email || dataForm?.phone,
        },
        dataForm?.guest || false,
      )
        .then(() => {
          setLoadingResend(false);
          disableResendSMS();
          setOTPValidity();
          toastrSuccess('OTP Resend successfull');
        })
        .catch((error: any) => {
          setLoadingResend(false);
          toastrError(error?.response?.data?.message);
        });
    }
    setCountOTPResends(countOTPResends + 1);
  };
  {
    /*useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://smtpjs.com/v3/smtp.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (countOTPResends > 0) {
      if (window.Email) {
        window.Email.send({
          Host: 'smtp.elasticemail.com',
          Port: 2525,
          Username: 'matthewmridha@fcbt.com.bd',
          Password: 'DFF8CDA0DA03E976C601D9FDF025809772ED', // Obtain this from smtpjs.com
          To: 'matthewmridha@fcbt.com.bd',
          From: 'matthewmridha@fcbt.com.bd',
          Subject: 'Multiple Resend OTP Action',
          Body: `${
            dataForm?.email || dataForm?.phone
          } has made ${countOTPResends} OTP resend requests`,
        })
          .then((message: any) => console.log(message))
          .catch(() => console.log('Failed to send email.'));
      } else {
        console.log('SMTP.js is not loaded.');
      }
    }
  }, [countOTPResends]);*/
  }

  return (
    <div className="sms-verify">
      {loading ? <DotLoader /> : null}
      <div className="verify-description">
        <>
          {guestNumber && guestNumber.length > 1 ? (
            <p>{`A Verification Code has been sent to ${guestNumber} via SMS`}</p>
          ) : dataForm && dataForm?.phone ? (
            <p>{`A Verification Code has been sent to ${dataForm?.phone} via SMS`}</p>
          ) : (
            <p>{`A Verification Code has been sent to your phone via SMS`}</p>
          )}
        </>
      </div>
      <AdaptiveInput
        type="text"
        for="email"
        name="email"
        label={`Enter the 6-digit Verification Code`}
        value={code}
        handleChange={handleChange}
        validate={errorMessage ? true : false}
        validateContent={errorMessage}
        //handleBlurInput={checkValidEmail}
      />
      {OTPisValid ? (
        <div className="verify-description">
          Verification code expires in{' '}
          {Math.floor(remainingOTPTime / 60).toString() +
            ' : ' +
            Math.floor(remainingOTPTime % 60)
              .toString()
              .padStart(2, '0')}{' '}
          minutes
        </div>
      ) : null}
      <div className="verify-buttons">
        <button
          className={`btn ${resendDisabled ? 'disable' : ''}`}
          onClick={handleResend}
          disabled={loadingResend || resendDisabled}
          style={{ flex: 0.7 }}
        >
          {loadingResend
            ? 'Resending...'
            : resendDisabled
            ? `Resend OTP in ${
                Math.floor(remainingDisabledTime / 60).toString() +
                ' : ' +
                Math.floor(remainingDisabledTime % 60)
                  .toString()
                  .padStart(2, '0')
              }`
            : 'Resend OTP'}
        </button>
        <button
          className={`btn ${code?.length < 6 && 'disable'}`}
          disabled={code?.length < 6 || loading}
          onClick={handleVerify}
        >
          Verify
        </button>
      </div>
      <div style={{ textAlign: 'left' }}>
        <Stack>
          <Typography variant="overline" gutterBottom align="left">
            Having trouble finding your OTP?
          </Typography>
          <Typography variant="caption" align="left">
            1. Please <b>check you SPAM SMS</b> folder
          </Typography>
          <Typography variant="caption" align="left">
            2. If you haven&apos;t received your OTP, click <b>Resend OTP</b> after 2 minutes.
          </Typography>
          <Typography variant="caption" align="left">
            3. Still having trouble? Contact our customer care service at{' '}
            {countOTPResends > 0 ? (
              <Button variant="outlined" startIcon={<CallIcon />}>
                <a href={`tel:${PHONE_CONTACT}`}>{PHONE_CONTACT}</a>
              </Button>
            ) : (
              <b>
                <a href={`tel:${PHONE_CONTACT}`}>{PHONE_CONTACT}</a>
              </b>
            )}
          </Typography>
        </Stack>
      </div>
      <p>
        *Please Collect your Sundora Privilege Card from a Sundora store of your choice at a
        time of your convenience
      </p>
    </div>
  );
};

export default SMSVerify;
