import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import BlockModal from '../BlockModal';
import * as action from '../../redux/auth/actions';
import './login-modal.scss';
import {
  fetchClient,
  formatPhoneNumber,
  toastrError,
  toastrSuccess,
  validateEmail,
  validatePhone,
} from '../../redux/Helpers';
import {
  GET_SOCIAL_MEDIA_CLIENT_IDS,
  POST_CHECK_VALID_EMAIL,
  VERIFY_SMS,
  VERIFY_SMS_GUEST,
  REQUEST_MAGIC_LINK,
  DISABLE_PASSWORDLESS_LOGIN_TIME,
  SEND_LOGIN_OTP,
  VERIFY_REAL_EMAIL,
} from '../../config';
import { Link, useHistory } from 'react-router-dom';
import AdaptiveInput from '../CheckoutForm/AdaptiveInput';
import cookies from '../../redux/Helpers/cookies';
import { isMobile } from '../../DetectScreen';
import Select from 'react-dropdown-select';
import { toLower, trim } from 'lodash';
import DotLoader from '../DotLoader';
import SMSVerify from './SMSVerify';
import axios from 'axios';
import { setCheckoutV2StateAction } from '../../redux/checkoutV2/actions';
import successful from '../../images/icons/successful-payment.svg';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import OtpInput from 'react-otp-input';
import { EnvelopeIcon, DevicePhoneMobileIcon, KeyIcon } from '@heroicons/react/24/solid';

function LoginModal(props: any): JSX.Element {
  const willNotLoginAfterRegister: any = props?.willNotLoginAfterRegister;
  const dispatch = useDispatch();
  const [successMessage] = useState('');
  const [noInput, setNoInput] = useState<boolean>(false);
  const [alignment, setAlignment] = useState<any>('otp');
  const [OTPInput, setOTPInput] = useState<boolean>(false);
  const [otp, setOtp] = useState('');
  const [dataForm, setDataForm] = useState<any>({
    email: '',
    username: '',
    password: '',
    password_confirm: '',
    phone: props?.phone || '',
    firstName: '',
    lastName: '',
    birthday: '',
    gender: '',
    newsletter_email: true,
    newsletter_sms: true,
  });
  const stateUser = useSelector((state: RootStateOrAny) => state.userReducer);
  const [validEmail, setValidEmail] = useState<any>(
    props?.validEmail === false ? false : true,
  );
  const [validateForm, setValidateForm] = useState<any>({
    email: '',
    username: '',
    password: '',
    password_confirm: '',
    phone: '',
    firstName: '',
    lastName: '',
    birthday: '',
    gender: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [openSMSVerify, setOpenSMSVerify] = useState<boolean>(false);
  const islogged = localStorage.getItem('sundoraToken') && cookies.get('token');
  const [passwordLessLoginSent, setPasswordLessLoginSent] = useState<boolean>(false);
  const [passwordlessDisabled, setPasswordlessDisabled] = useState<boolean>(false);
  const [remainingPasswordlessDisabledTime, setRemainingPasswordlessDisabledTime] =
    useState<number>(0);
  let PasswordlessTimerInterval: any;

  const checkValidEmail = async (option: any = '') => {
    const validCheckEmail = validateEmail(dataForm?.email?.trim());
    const validCheckPhone = validatePhone(dataForm?.email?.trim());

    if (!validCheckEmail && validCheckPhone) {
      setDataForm((prev: any) => ({
        ...prev,
        phone: dataForm.email.trim(),
      }));
    }

    if (!validCheckEmail && !validCheckPhone) {
      return `Please enter valid email or phone`;
    }

    const options = {
      url: POST_CHECK_VALID_EMAIL,
      method: 'POST',
      body: {
        email: (dataForm.email as string).toLowerCase().trim(),
      },
    };

    const res = await fetchClient(options);
    setValidEmail(res?.success);
    if (!validEmail && res?.success) {
      return `Please choose another email`;
    }

    if (option === 'register') {
      const verify_email_options = {
        url: VERIFY_REAL_EMAIL,
        method: 'POST',
        body: {
          email: (dataForm.email as string).toLowerCase().trim(),
        },
      };

      const emailIsReal = await fetchClient(verify_email_options);
      if (emailIsReal != 'valid') {
        return `Please chose a valid email address`;
      }
    }

    if (!validCheckEmail) {
      return `Please enter valid email`;
    }

    return res?.success;
  };

  //const checkValidPassword = () => {
  //  if (!dataForm.password || dataForm?.password == '') {
  //    return 'Please enter password';
  //  }
  //  const validPassword = dataForm.password.length >= 8;

  //  if (validPassword == false) {
  //    return `Please enter a password of more than 8 characters`;
  //  }
  //};

  //const checkValidPasswordCofirm = () => {
  //  if (dataForm?.password != dataForm?.password_confirm) {
  //    return 'Confirm password is not known correctly';
  //  }
  //};

  const checkValidPhone = async () => {
    if (!dataForm.phone || dataForm?.phone == '') {
      return 'Please enter phone number';
    }
    const phone = formatPhoneNumber(dataForm.phone);
    const validPhone = validatePhone(phone);

    if (validPhone == false) {
      return `Please enter valid phone number`;
    }

    const options = {
      url: POST_CHECK_VALID_EMAIL,
      method: 'POST',
      body: {
        email: phone,
      },
    };
    try {
      const response = await fetchClient(options);
      if (response.success) {
        return `Please choose another phone number`;
      }
    } catch (error) {
      //
    }
  };

  const checkValidFirstName = () => {
    if (!dataForm.firstName || dataForm?.firstName == '') {
      return 'Please enter first name';
    }
  };

  const checkValidLastName = () => {
    if (!dataForm.lastName || dataForm?.lastName == '') {
      return 'Please enter last name';
    }
  };

  const checkValidForm = async () => {
    const validEmail = await checkValidEmail('register');
    //const validPassword = checkValidPassword();
    //const validPasswordConfirm = checkValidPasswordCofirm();
    const validFirstName = checkValidFirstName();
    const validLastName = checkValidLastName();
    const validPhone = await checkValidPhone();
    setValidateForm({
      ...validateForm,
      ...{
        email: validEmail,
        //password: validPassword,
        //password_confirm: validPasswordConfirm,
        firstName: validFirstName,
        lastName: validLastName,
        phone: validPhone,
      },
    });

    if (
      validEmail ||
      //validPassword ||
      //validPasswordConfirm ||
      validFirstName ||
      validLastName ||
      validPhone
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, isCheckbox = false) => {
    setNoInput(false);
    let value: any = event.target.value;
    if (isCheckbox) value = event.target.checked;
    setDataForm({ ...dataForm, [event.currentTarget.name]: value });
  };

  const handleChangeSelect = (keyName: any, value: any) => {
    setDataForm({
      ...dataForm,
      [keyName]: trim(toLower(value[0].name)),
    });
  };

  const handleLogin = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const options = {
      url: POST_CHECK_VALID_EMAIL,
      method: 'POST',
      body: {
        email: (dataForm.email as string).toLowerCase().trim(),
      },
    };

    const res = await fetchClient(options);
    if (!res?.success) {
      setValidEmail(false);
      return;
    } else {
      const draft_order_id = localStorage.getItem('id_checkout')
        ? localStorage.getItem('id_checkout')
        : null;
      dispatch(
        action.loginActions({
          email: (dataForm.email as string).toLowerCase(),
          password: dataForm?.password?.length > 0 ? dataForm?.password : null,
          draft_order_id: draft_order_id,
          otp: dataForm?.otp?.length > 0 ? dataForm?.otp : null,
        }),
      );
    }
  };

  const handleregister = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const valid = await checkValidForm();
    if (valid) {
      setLoading(false);
      return false;
    }
    const draft_order_id = localStorage.getItem('id_checkout')
      ? localStorage.getItem('id_checkout')
      : null;
    dispatch(
      action.registerActions({
        customer: {
          email: dataForm.email ? (dataForm.email as string).toLowerCase() : null,
          //password: dataForm.password,
          //password_confirmation: dataForm.password,
          phone: formatPhoneNumber(dataForm.phone),
          draft_order_id: draft_order_id,
          first_name: dataForm.firstName,
          last_name: dataForm.lastName,
          note: {
            birthday: dataForm.birthday,
            gender: dataForm.gender,
          },
          withTimeout: true,
          newsletter_email: dataForm.newsletter_email,
          newsletter_sms: dataForm.newsletter_sms,
        },
      }),
    );

    setOpenSMSVerify(true);
  };

  const PasswordlessdisabledInStorage = () => {
    const now = Math.floor(Date.now() / 1000);
    let timeLimit = 0;
    const disabledRquestPassweordlessTimeInStorage = localStorage.getItem('PLLBlockTime');
    if (disabledRquestPassweordlessTimeInStorage) {
      timeLimit = parseInt(JSON.parse(disabledRquestPassweordlessTimeInStorage));
    }
    if (timeLimit > now) {
      setRemainingPasswordlessDisabledTime(timeLimit - now);
      return true;
    } else {
      localStorage.removeItem('PLLlockTime');
    }
    return false;
  };

  const disableRequestPasswordless = () => {
    const isDisabled = PasswordlessdisabledInStorage();
    if (!isDisabled) {
      const disablePasswordlessRequestUntil =
        Math.floor(Date.now() / 1000) + DISABLE_PASSWORDLESS_LOGIN_TIME * 60;
      setRemainingPasswordlessDisabledTime(DISABLE_PASSWORDLESS_LOGIN_TIME * 60);
      localStorage.setItem('PLLBlockTime', JSON.stringify(disablePasswordlessRequestUntil));
    }
    setPasswordlessDisabled(true);
  };

  useEffect(() => {
    const isDisabled = PasswordlessdisabledInStorage();
    if (!isDisabled) {
      setPasswordlessDisabled(false);
    } else {
      setPasswordlessDisabled(true);
    }
  }, [remainingPasswordlessDisabledTime]);

  useEffect(() => {
    if (passwordlessDisabled) {
      PasswordlessTimerInterval = setInterval(() => {
        setRemainingPasswordlessDisabledTime(
          (prevRemainingPasswordlessDisabledTime) => prevRemainingPasswordlessDisabledTime - 1,
        );
      }, 1000);
    }
    return () => clearInterval(PasswordlessTimerInterval);
  }, [passwordlessDisabled]);

  useEffect(() => {
    if (remainingPasswordlessDisabledTime <= 0) {
      clearInterval(PasswordlessTimerInterval);
      setPasswordlessDisabled(false);
    }
  }, [remainingPasswordlessDisabledTime]);

  const handelToggleModal = () => {
    dispatch(action.toggleModalLoginActions(false));
  };

  const handleKeyPress = () => {
    dispatch(action.toggleModalLoginActions(false));
  };
  const history = useHistory();
  const directForgotPassword = () => {
    dispatch(action.toggleModalLoginActions(false));
    history.push('/forgot-password');
  };

  useEffect(() => {
    const options = {
      url: GET_SOCIAL_MEDIA_CLIENT_IDS,
      method: 'GET',
      body: null,
    };
    fetchClient(options).then((res) => {
      if (res?.success) {
      }
    });
  }, []);

  useEffect(() => {
    if (stateUser.loginRequestStatus.error) {
      setLoading(false);
      console.log(stateUser.loginRequestStatus.error);
    }
  }, [stateUser.loginRequestStatus.error]);

  useEffect(() => {
    setPasswordLessLoginSent(false);
  }, [stateUser.modalLogin]);

  const renderLoginError = () => {
    if (noInput) {
      return <p className="err-login">Please enter your email or phone number</p>;
    }
    if (
      validEmail &&
      !stateUser.loginRequestStatus.inProgress &&
      stateUser.loginRequestStatus.error
    ) {
      if (stateUser.loginRequestStatus.exists && !stateUser.loginRequestStatus.email_verified)
        return (
          <p className="err-login">
            Email not verified, new magic link has been sent to your email
          </p>
        );
      else if (
        stateUser.loginRequestStatus.exists &&
        !stateUser.loginRequestStatus.sms_verified
      ) {
        setOpenSMSVerify(true);
      } else return <p className="err-login">Wrong username or password</p>;
    }
    return null;
  };

  const handleVerifySMS = (data: any) => {
    console.log('handleVerifySMS', data);
    axios
      .post(dataForm?.guest ? VERIFY_SMS_GUEST : VERIFY_SMS, data)
      .then((res: any) => {
        dispatch(
          setCheckoutV2StateAction({
            smsVerifyAsGuest: true,
            guestPhone: dataForm?.phone,
          }),
        );
        toastrSuccess('SMS verification successful');
        toastrSuccess('Registration Successful');
        setOpenSMSVerify(false);

        if (res?.data?.data) {
          !willNotLoginAfterRegister &&
            dispatch(action.verificationSuccessActions(res.data?.data));
        }
      })
      .catch(() => {
        toastrError('Code incorrect or expired');
      });
  };

  const requestPasswordlessLooginLink = () => {
    if (!passwordlessDisabled && !stateUser.loginRequestStatus.inProgress && !loading) {
      setNoInput(false);
      const valid = checkValidEmail();
      if (!valid || dataForm.email.length < 1) {
        setNoInput(true);
        return;
      } else {
        setLoading(true);
        const options = {
          url: REQUEST_MAGIC_LINK,
          method: 'POST',
          body: {
            email: (dataForm.email as string)?.toLowerCase(),
          },
        };
        fetchClient(options).then((res) => {
          setLoading(false);
          if (res?.success) {
            setPasswordLessLoginSent(true);
            disableRequestPasswordless();
          } else if (res?.isError) {
            console.log('not found');
          }
        });
      }
    }
  };

  const RequestOTPLogin = () => {
    if (!passwordlessDisabled && !stateUser.loginRequestStatus.inProgress && !loading) {
      setNoInput(false);
      const valid = checkValidEmail();
      if (!valid || dataForm.email.length < 1) {
        setNoInput(true);
        return;
      } else {
        setLoading(true);
        const options = {
          url: SEND_LOGIN_OTP,
          method: 'POST',
          body: {
            email: (dataForm.email as string)?.toLowerCase(),
          },
        };
        fetchClient(options).then((res) => {
          setLoading(false);
          if (res?.success) {
            toastrSuccess(res.detail);
            toggleOTPInput();
          } else if (res?.isError) {
            toastrError(res.message);
          }
        });
      }
    }
  };

  const handleTabChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setDataForm({ ...dataForm, email: null });
    setOTPInput(false);
    setAlignment(newAlignment);
  };

  useEffect(() => {
    setDataForm({ ...dataForm, otp: otp });
  }, [otp]);

  const toggleOTPInput = () => setOTPInput(!OTPInput);

  return (
    <section className="loginModal">
      <BlockModal
        isOpen={stateUser.modalLogin}
        minWidth={isMobile ? '70%' : '36%'}
        maskBg={true}
        onKeyPress={!successMessage?.length ? () => handleKeyPress() : () => null}
        onClickAway={!successMessage?.length ? () => handelToggleModal() : () => null}
        showCloseButton={!successMessage?.length}
      >
        {loading ? '' : ''}
        {passwordLessLoginSent ? (
          <div
            className="wrapper-form"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src={successful}
              alt=""
              style={{
                width: '80px',
                marginBottom: '16px',
              }}
            />
            <h3>Passwordless Login Link Sent</h3>
            <div style={{ textAlign: 'left' }}>
              <h4>Kindly check your inbox and open the email from us.</h4>
              <h4>
                Click on the “Login Link” to securely access your account and explore your
                exclusive offers!
              </h4>
            </div>
          </div>
        ) : (
          <div className="wrapper-form">
            {!successMessage?.length && !openSMSVerify ? (
              <h2 className={`title-form ${islogged ? 'logged' : ''}`}>
                {validEmail ? 'SIGN IN' : 'REGISTER'}
              </h2>
            ) : openSMSVerify ? (
              <h2 className={`title-form`}>OTP Verification</h2>
            ) : null}

            {openSMSVerify && (
              <SMSVerify
                dataForm={dataForm}
                setOpenSMSVerify={setOpenSMSVerify}
                handleVerify={handleVerifySMS}
              />
            )}

            {islogged || successMessage?.length || openSMSVerify ? (
              ''
            ) : (
              <>
                {validEmail ? (
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleTabChange}
                    aria-label="login options"
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      flexBasis: '100%',
                      marginBottom: '10px',
                      padding: '0',
                    }}
                  >
                    <ToggleButton value="otp" style={{ flex: '1' }}>
                      <DevicePhoneMobileIcon
                        className="h-5 w-5"
                        style={
                          isMobile
                            ? {
                                height: '16px',
                                width: '16px',
                                marginRight: '5px',
                                fontSize: '14px',
                              }
                            : { height: '24px', width: '24px', marginRight: '10px' }
                        }
                      />
                      OTP
                    </ToggleButton>
                    <ToggleButton value="email-link" style={{ flex: '1' }}>
                      <EnvelopeIcon
                        className="h-5 w-5"
                        style={
                          isMobile
                            ? {
                                height: '16px',
                                width: '16px',
                                marginRight: '5px',
                                fontSize: '14px',
                              }
                            : { height: '24px', width: '24px', marginRight: '10px' }
                        }
                      />
                      EMAIL LINK
                    </ToggleButton>

                    <ToggleButton value="password" style={{ flex: '1' }}>
                      <KeyIcon
                        className="h-5 w-5"
                        style={
                          isMobile
                            ? {
                                height: '16px',
                                width: '16px',
                                marginRight: '5px',
                                fontSize: '14px',
                              }
                            : { height: '24px', width: '24px', marginRight: '10px' }
                        }
                      />
                      PASSWORD
                    </ToggleButton>
                  </ToggleButtonGroup>
                ) : null}

                <div className={`${!validEmail ? 'd-flex-form' : ''}`}>
                  <div
                    className={`form-group ${
                      alignment === 'otp' && validEmail ? 'phone-input' : ''
                    }`}
                    style={{ marginBottom: '24px' }}
                  >
                    <label htmlFor="phone" className="animated faster fade-in">
                      {validEmail ? (
                        <>
                          {alignment === 'otp' ? (
                            <>
                              Phone Number<span className="required">*</span>
                            </>
                          ) : alignment === 'email-link' ? (
                            <>
                              Email <span className="required">*</span>
                            </>
                          ) : (
                            <>
                              Email or Phone Number<span className="required">*</span>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          Email<span className="required">*</span>
                        </>
                      )}
                    </label>
                    <AdaptiveInput
                      type="text"
                      for="email"
                      name="email"
                      label={`Enter ${
                        validEmail
                          ? alignment === 'otp'
                            ? 'Phone Number'
                            : alignment === 'email-link'
                            ? 'Email'
                            : alignment === 'password'
                            ? 'Email or Phone Number'
                            : ''
                          : 'Email'
                      }`}
                      value={dataForm.email}
                      handleChange={handleChange}
                      validate={validEmail ? false : true}
                      validateContent={validateForm.email}
                      handleBlurInput={checkValidEmail}
                    />
                  </div>
                  {!validEmail ? (
                    <div className="form-group phone-input">
                      <label htmlFor="phone" className="animated faster fade-in">
                        Phone number<span className="required">*</span>
                      </label>
                      <AdaptiveInput
                        type="text"
                        for="Phone number"
                        label="Phone number"
                        name="phone"
                        value={dataForm.phone}
                        validate={true}
                        validateContent={validateForm.phone}
                        handleChange={handleChange}
                      />
                      <div
                        className="input-description"
                        style={{ fontSize: '0.7em', lineHeight: '190%' }}
                      >
                        An OTP will be sent to your number
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={`${!validEmail ? 'd-flex-form' : ''}`}>
                  <div className="form-group" style={{ marginBottom: '24px' }}>
                    {validEmail ? (
                      <div>
                        {alignment === 'password' ? (
                          <div>
                            <label htmlFor="password" className="animated faster fade-in">
                              Enter your password to login
                            </label>
                            <AdaptiveInput
                              type="password"
                              for="password"
                              name="password"
                              label="Enter password"
                              value={dataForm.password}
                              validate={true}
                              validateContent={validateForm.password}
                              handleChange={handleChange}
                            />
                          </div>
                        ) : null}
                        {OTPInput ? (
                          <>
                            <label htmlFor="OTP" className="animated faster fade-in">
                              Enter your OTP to login
                            </label>
                            {/*<AdaptiveInput
                              type="text"
                              inpumode="numeric"
                              for="otp"
                              name="otp"
                              label="Enter OTP"
                              value={dataForm.otp}
                              validate={true}
                              validateContent={validateForm.password}
                              handleChange={handleChange}
                              autocomplete="one-time-code"
                            />*/}
                            <OtpInput
                              value={dataForm.otp}
                              onChange={setOtp}
                              numInputs={6}
                              renderSeparator={<span> - </span>}
                              shouldAutoFocus={true}
                              inputStyle={{
                                width: isMobile ? '35px' : '50px',
                                height: isMobile ? '35px' : '50px',
                                margin: isMobile ? '5px' : '10px',
                                borderRadius: '5px',
                                boxShadow: 'none',
                              }}
                              containerStyle={{
                                width: '100%',
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                              renderInput={(props) => <input {...props} />}
                            />
                            <button style={{ marginTop: '5px' }} onClick={RequestOTPLogin}>
                              Resent OTP
                            </button>
                          </>
                        ) : null}
                      </div>
                    ) : null}

                    {/*<AdaptiveInput
                      type="password"
                      for="password"
                      name="password"
                      label="Enter password"
                      value={dataForm.password}
                      validate={true}
                      validateContent={validateForm.password}
                      handleChange={handleChange}
                    />*/}
                  </div>
                  {/*!validEmail ? (
                    <div className="form-group" style={{ marginBottom: '24px' }}>
                      <label htmlFor="password_confirm" className="animated faster fade-in">
                        Password confirm<span className="required">*</span>
                      </label>

                      <AdaptiveInput
                        type="password"
                        for="password_confirm"
                        name="password_confirm"
                        label="Enter password confirm"
                        value={dataForm.password_confirm}
                        validate={true}
                        validateContent={validateForm.password_confirm}
                        handleChange={handleChange}
                      />
                    </div>
                  ) : (
                    ''
                  )*/}
                </div>
                {!validEmail ? (
                  <div className="d-flex-form">
                    <div className="form-group" style={{ marginBottom: '24px' }}>
                      <label htmlFor="first_name" className="animated faster fade-in">
                        First name<span className="required">*</span>
                      </label>
                      <AdaptiveInput
                        type="text"
                        for="first_name"
                        name="firstName"
                        label="Enter first name"
                        value={dataForm.firstName}
                        validate={true}
                        validateContent={validateForm.firstName}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="form-group" style={{ marginBottom: '24px' }}>
                      <label htmlFor="last_name" className="animated faster fade-in">
                        Last name<span className="required">*</span>
                      </label>
                      <AdaptiveInput
                        type="text"
                        for="last_name"
                        name="lastName"
                        label="Enter last name"
                        value={dataForm.lastName}
                        validate={true}
                        validateContent={validateForm.lastName}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="form-group" style={{ marginBottom: '24px' }}>
                      <label htmlFor="last_name" className="animated faster fade-in">
                        Enter your birthday - Optional
                      </label>
                      <AdaptiveInput
                        type="date"
                        for="birthday"
                        name="birthday"
                        label=""
                        value={dataForm.birthday}
                        validate={true}
                        validateContent={validateForm.birthday}
                        handleChange={handleChange}
                      />
                    </div>
                    <div className="form-group select-group">
                      <label htmlFor={'gender'} className="text-help animated faster fade-in">
                        Enter gender - Optional
                      </label>
                      <Select
                        placeholder="Select"
                        className="primary-select"
                        name="gender"
                        required={false}
                        searchable={false}
                        labelField="name"
                        onChange={(e: any) => {
                          handleChangeSelect('gender', e);
                        }}
                        valueField="name"
                        options={[
                          { id: 1, name: 'Other' },
                          { id: 2, name: 'Male' },
                          { id: 3, name: 'Female' },
                        ]}
                        multi={false}
                        values={[]}
                      />
                    </div>
                    <div className="newsletter">
                      <label className="checkbox-button" htmlFor="newsletter_email">
                        <input
                          type="checkbox"
                          className="checkbox-button__input"
                          id="newsletter_email"
                          name="newsletter_email"
                          onChange={(e) => handleChange(e, true)}
                          checked={dataForm.newsletter_email}
                        />
                        <div className="checkbox-button__control"></div>
                      </label>
                      <label className="checkbox-button__label" htmlFor="newsletter_email">
                        I consent to Sundora and its service providers, sending me marketing
                        information and materials of Sundora&apos;s and its partners&apos;
                        products, services and events, by email and SMS
                      </label>
                      &nbsp;
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {renderLoginError()}
                {stateUser.loginRequestStatus.inProgress || loading ? <DotLoader /> : null}
                {validEmail ? (
                  <div
                    className="submit-box"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      alignContent: 'space-between',
                      alignItems: isMobile ? 'center' : 'stretch',
                    }}
                  >
                    {alignment === 'otp' && !OTPInput ? (
                      <button
                        className="btn-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={RequestOTPLogin}
                        disabled={stateUser.loginRequestStatus.inProgress}
                        id="otp-login-button"
                      >
                        REQUEST LOGIN OTP
                      </button>
                    ) : alignment === 'email-link' ? (
                      <button
                        className={
                          passwordlessDisabled ||
                          stateUser.loginRequestStatus.inProgress ||
                          loading
                            ? 'btn-primary disabled'
                            : 'btn-primary'
                        }
                        style={{ cursor: 'pointer' }}
                        onClick={requestPasswordlessLooginLink}
                        disabled={
                          passwordlessDisabled ||
                          stateUser.loginRequestStatus.inProgress ||
                          loading
                        }
                        id="email-login-button"
                      >
                        {passwordlessDisabled
                          ? `Please wait ${
                              Math.floor(remainingPasswordlessDisabledTime / 60).toString() +
                              ':' +
                              Math.floor(remainingPasswordlessDisabledTime % 60)
                                .toString()
                                .padStart(2, '0')
                            } to request PASSWORDLESS LOGIN`
                          : 'SIGN IN WITH EMAIL LINK'}
                      </button>
                    ) : alignment === 'password' || (alignment === 'otp' && OTPInput) ? (
                      <button
                        className="btn-primary"
                        style={{ cursor: 'pointer' }}
                        onClick={(event) => handleLogin(event)}
                        disabled={stateUser.loginRequestStatus.inProgress}
                        id="password-login-button"
                      >
                        SIGN IN
                      </button>
                    ) : null}

                    {validEmail ? (
                      <div
                        style={{
                          flexBasis: 'auto',
                          marginLeft: isMobile ? 0 : 'auto',
                          alignSelf: isMobile ? 'center' : 'flex-end',
                          textAlign: isMobile ? 'center' : 'right',
                          //marginRight: isMobile ? 0 : '10px',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'row',
                          marginTop: '10px',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {' '}
                        Don&apos;t have an account?{' '}
                        <button
                          className="btn-forgot-password"
                          onClick={() => setValidEmail(false)}
                          style={{ margin: '0px', padding: '0px', width: '100px' }}
                        >
                          Register
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="submit-box" style={{ flexWrap: 'wrap' }}>
                    <button className="btn-primary" onClick={(event) => handleregister(event)}>
                      REGISTER
                    </button>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      Already have an account?{' '}
                      <button
                        className="btn-forgot-password"
                        onClick={() => setValidEmail(true)}
                        style={{ width: '100px' }}
                      >
                        Sign in
                      </button>
                    </div>

                    <div className="terms-cond">
                      By signing up you confirm that you have read and accepted our{' '}
                      <Link to={'/about-us/terms-conditions'} onClick={handelToggleModal}>
                        {' '}
                        Terms & conditions
                      </Link>{' '}
                      and{' '}
                      <Link to={'/about-us/privacy-policy'} onClick={handelToggleModal}>
                        Privacy Policy
                      </Link>
                    </div>
                    {validEmail ? (
                      <div
                        style={{
                          marginLeft: 'auto',
                          alignSelf: 'flex-end',
                          textAlign: 'right',
                          fontSize: 14,
                          marginTop: '1rem',
                        }}
                      >
                        <a
                          className="btn-forgot-password"
                          style={{ cursor: 'pointer' }}
                          onClick={directForgotPassword}
                        >
                          Forgot password?
                        </a>
                      </div>
                    ) : null}
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </BlockModal>
    </section>
  );
}
export default LoginModal;
